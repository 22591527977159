<template>
  <v-row>
    <v-col cols="11" sm="5">
      <v-dialog
        ref="dialog"
        v-model="modal2"
        :return-value.sync="time"
        persistent
        width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="time"
            :label="label"
            prepend-icon="mdi-clock-time-four-outline"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-time-picker v-if="modal2" v-model="time" full-width>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="modal2 = false"> Cancelar </v-btn>
          <v-btn text color="primary" @click="setTime(time)">
            OK
          </v-btn>
        </v-time-picker>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    label: {
      default: 'Horario',
    }
  },
  data() {
    return {
      time: null,
      menu2: false,
      modal2: false,
    };
  },
  methods: {
    setTime(time) {
      this.$refs.dialog.save(time);
      this.$emit("dateSelected", { time: this.time });
    },
  },
};
</script>