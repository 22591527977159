<template>
  <div>
    <div class="text-center">
      <v-dialog
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
        v-model="dialogEdit"
      >
        <v-card>
          <v-toolbar dark color="primary">
            <v-toolbar-title>Editar compromisso</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="dialogEdit = false">
              <v-icon>fas fa-times</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-title> </v-card-title>
          <v-divider></v-divider>
          <v-container>
            <v-form v-model="validEvento">
              <v-row>
                <v-col cols="6" sm="6" md="6">
                  <DatePick
                    :label="convertSemHorario(eventoEditado.start)"
                    required
                    @dateSelected="getDateEdit"
                  />
                </v-col>
                <v-col>
                  <div class="d-flex flex-row align-center">
                    <v-select
                      :rules="rules['obrigatorio']"
                      :items="tipos"
                      item-text="tipo"
                      item-value="tipo"
                      v-model="eventoEditado.tipo"
                    ></v-select>
                    <v-btn
                      @click="dialogTipo = true"
                      class="ml-2"
                      color="primary"
                      x-small
                    >
                      <v-icon x-small> fas fa-plus </v-icon> novo
                    </v-btn>
                  </div>
                </v-col>
                <v-col cols="6" sm="6" md="6">
                  <Time
                    @dateSelected="getTimeInicioEdit"
                    :label="eventoEditado.horaInicio"
                  />
                </v-col>
                <v-col cols="6" sm="6" md="6">
                  <Time
                    @dateSelected="getTimeFimEdit"
                    :label="eventoEditado.horaFim"
                  />
                </v-col>

                <v-col cols="12" sm="6" md="6">
                  <v-select
                    multiple
                    :rules="rules['obrigatorio']"
                    :items="users"
                    v-model="eventoEditado.solicitantes"
                    :item-text="'name'"
                    item-value="id"
                    label="Solicitantes"
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-select
                    :items="users"
                    :rules="rules['responsaveis']"
                    v-model="eventoEditado.responsaveis"
                    item-text="name"
                    item-value="id"
                    label="Responsavel"
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-select
                    multiple
                    :items="users"
                    :rules="rules['responsaveis']"
                    v-model="eventoEditado.coresponsaveis"
                    item-text="name"
                    item-value="id"
                    label="Co-Responsaveis"
                  ></v-select>
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    label="Descrição"
                    v-model="eventoEditado.descricao"
                    :rules="rules['obrigatorio']"
                  ></v-textarea>
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    label="Complemento"
                    v-model="eventoEditado.complemento"
                  ></v-textarea>
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    label="Observação"
                    v-model="eventoEditado.observacao"
                  ></v-textarea>
                </v-col>
                <v-col cols="12" class="text-center">
                  <v-btn
                    color="primary"
                    :disabled="!validEvento"
                    @click="editarEvento()"
                  >
                    Salvar
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card>
      </v-dialog>

      <v-dialog width="600" v-model="dialogTrash">
        <v-card class="text-center">
          <v-card-title>
            Você tem certeza que deseja excluir este compromisso?
          </v-card-title>
          <v-card-text>
            {{
              selectedEvent.tipo +
                " " +
                " de " +
                convertDate(selectedEvent.start) +
                " ate " +
                convertDate(selectedEvent.end)
            }}
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="d-flex text-center">
            <v-spacer></v-spacer>
            <v-btn color="error" @click="excluirEvento"> Confirmar </v-btn>
            <v-btn color="primary" @click="dialogTrash = false">
              Cancelar
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog width="500" v-model="dialogShow">
        <v-card class="pl-4 pr-4 pb-4 text-center">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title> Tipo </v-list-item-title>
              <v-list-item-subtitle>{{
                selectedEvent.tipo
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title> Horario </v-list-item-title>
              <v-list-item-subtitle>
                {{
                  `${selectedEvent.date} ${selectedEvent.horaInicio} / ${selectedEvent.horaFim}`
                }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-title> Tipo Solicitante </v-list-item-title>
              <v-list-item-subtitle>
                {{ selectedEvent.tipoSolicitante }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-title> Solicitantes </v-list-item-title>
              <v-list-item-subtitle
                v-for="solicitante in selectedEvent.solicitantes"
                :key="'solicitante' + solicitante"
              >
                {{ getNameUsuario(solicitante) }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-title> Responsaveis </v-list-item-title>
              <v-list-item-subtitle
                v-for="responsavel in selectedEvent.responsaveis"
                :key="'responsavel' + responsavel"
              >
                <template v-if="selectedEvent.tipoSolicitante == 'cliente'">
                  {{ getNameCliente(responsavel) }}
                </template>
                <template v-else>
                  {{ getNameUsuario(responsavel)["name"] }}
                </template>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-title> Status </v-list-item-title>

              <v-list-item-subtitle
                v-for="status in selectedEvent.status"
                :key="status"
              >
                {{ status }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-title> Descrição </v-list-item-title>
              <v-list-item-subtitle>
                {{ selectedEvent.descricao }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-title> Complemento </v-list-item-title>
              <v-list-item-subtitle>
                {{ selectedEvent.complemento }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-title> Observação </v-list-item-title>
              <v-list-item-subtitle>
                {{ selectedEvent.observacao }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-dialog>
      <v-dialog width="500" v-model="dialogTipo">
        <v-card class="pl-4 pr-4 pb-4 text-center">
          <v-card-title> Novo tipo de compromisso </v-card-title>
          <v-text-field v-model="novoTipo" label="Tipo"></v-text-field>

          <v-btn color="primary" @click="salvarTipo"> Salvar </v-btn>
        </v-card>
      </v-dialog>
      <v-dialog
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
        v-model="dialog"
      >
        <v-card>
          <v-toolbar dark color="primary">
            <v-toolbar-title>Novo compromisso </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="dialog = false">
              <v-icon>fas fa-times</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-title> </v-card-title>
          <v-divider></v-divider>
          <v-container>
            <v-form v-model="valid">
              <v-row>
                <v-col cols="6" sm="6" md="6">
                  <DatePick
                    required
                    @dateSelected="getDate"
                    label="Data Inicial"
                  />
                </v-col>
                <v-col>
                  <div class="d-flex flex-row align-center">
                    <v-select
                      :rules="rules['obrigatorio']"
                      :items="tipos"
                      item-text="tipo"
                      item-value="tipo"
                      v-model="evento.tipo"
                    ></v-select>
                    <v-btn
                      @click="dialogTipo = true"
                      class="ml-2"
                      color="primary"
                      x-small
                    >
                      <v-icon x-small> fas fa-plus </v-icon> novo
                    </v-btn>
                  </div>
                </v-col>
                <v-col cols="6" sm="6" md="6">
                  <Time @dateSelected="getTimeInicio" label="Horario Inicial" />
                </v-col>
                <v-col cols="6" sm="6" md="6">
                  <Time @dateSelected="getTimeFim" label="Horario Final" />
                </v-col>

                <v-col cols="12" v-if="typeEvento == 'andamento' || typeEvento == 'afazer'">
                  <v-select
                    label="Tipo de Instancia"
                    :items="instancias"
                    item-value="id"
                    item-text="name"
                    v-model="evento.instance_id"
                    :loading="loadingInstancias"
                  ></v-select>
                </v-col>

                <v-col cols="12" sm="6" md="6">
                  <v-select
                    multiple
                    :rules="rules['obrigatorio']"
                    :items="users"
                    v-model="evento.solicitantes"
                    :item-text="'name'"
                    item-value="id"
                    label="Solicitantes"
                  ></v-select>
                </v-col>
               <v-col cols="12" sm="6" md="6">
                  <v-select
                    :items="usersResponsaveis"
                    :rules="rules['responsaveis']"
                    v-model="evento.responsaveis"
                    item-text="name"
                    item-value="id"
                    label="Responsavel"
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-select
                    multiple
                    :items="users"
                    :rules="rules['responsaveis']"
                    v-model="evento.coresponsaveis"
                    item-text="name"
                    item-value="id"
                    label="Co-Responsaveis"
                  ></v-select>
                </v-col>
                <v-col cols="4">
                  <v-select
                    :items="recorrencias"
                    v-model="evento.recorrencia"
                    :rules="rules['obrigatorio']"
                    label="Recorrencia"
                  ></v-select>
                </v-col>
                <v-col cols="3" v-show="evento.recorrencia != 'nenhum'">
                  <DatePick
                    required
                    @dateSelected="getDataLimite"
                    label="Data limite"
                  ></DatePick>
                </v-col>
                <v-col cols="12">
                  <v-select
                    :items="status"
                    v-model="evento.status"
                    :rules="rules['obrigatorio']"
                    multiple
                    label="Status"
                  ></v-select>
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    label="Descrição"
                    v-model="evento.descricao"
                    :rules="rules['obrigatorio']"
                  ></v-textarea>
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    :rules="rules['obrigatorio']"
                    label="Complemento"
                    v-model="evento.complemento"
                  ></v-textarea>
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    :rules="rules['obrigatorio']"
                    label="Observação"
                    v-model="evento.observacao"
                  ></v-textarea>
                </v-col>
                <v-col cols="12">
                  <v-file-input
                    v-model="evento.arquivos"
                    label="Arquivos"
                    required
                    counter
                    multiple
                    show-size
                    truncate-length="15"
                  ></v-file-input>
                </v-col>
                <v-col cols="12" class="text-center">
                  <v-btn
                    color="primary"
                    :disabled="!valid"
                    @click="salvarEvento"
                  >
                    Salvar
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import ToastService from "@/services/ToastService";
import DatePick from "../../components/Date/Index.vue";
import Time from "../../components/Date/Time.vue";
import ClienteService from "@/services/Cliente/ClienteService";
import UserService from "@/services/User/UserService";
import instanceAxios from "../../plugins/axios";
export default {
  props: {
    processoDoc: String,
    modal: { type: Boolean, default: false },
    typeEvento: { type: String, required: false, default: "compromisso" },
  },
  watch: {
    modal: function(modal) {
      this.dialog = modal;
    },
    dialog: function(dialog) {
      if (!dialog) {
        this.$emit("closeModal");
      }
    },
  },
  components: {
    DatePick,
    Time,
  },

  async mounted() {
    await this.showUsers();
    await this.showClientes();
    await this.loadTipos();
    this.getInstancias();
  },

  data() {
    return {
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      dialogShow: false,
      dialogTrash: false,
      dialogEdit: false,

      eventos: [],
      valid: false,
      rules: {
        texto: [(v) => !!v || "Campo obrigatorio"],
        obrigatorio: [(v) => !!v || "Campo obrigatorio"],
      },
      novoTipo: "",
      tipos: [],
      dialogTipo: false,
      ClienteService: new ClienteService(""),
      UserService: new UserService(""),
      users: [],
      clientes: [],
      recorrencias: ["nenhum", "diario", "semanal", "mensal"],
      status: [
        "Iniciado",
        "Realizado",
        "Privado",
        "Não editavel",
        "Cancelado",
        "Urgente",
      ],
      evento: {
        tipoSolicitante: "cliente",
      },
      eventoEditado: {},
      dialog: false,
      validEvento: false,
      type: "day",
      types: [
        { value: "month", label: "Mês" },
        { value: "week", label: "Semana" },
        { value: "day", label: "Dia" },
      ],
      mode: "stack",
      modes: ["stack", "column"],
      weekday: [0, 1, 2, 3, 4, 5, 6],
      weekdays: [
        { text: "Sun - Sat", value: [0, 1, 2, 3, 4, 5, 6] },
        { text: "Mon - Sun", value: [1, 2, 3, 4, 5, 6, 0] },
        { text: "Mon - Fri", value: [1, 2, 3, 4, 5] },
        { text: "Mon, Wed, Fri", value: [1, 3, 5] },
      ],
      value: "",
      events: [],
      colors: ["blue", "indigo"],
      names: ["Demanda", "Evento"],
      loadingInstancias: false,
      instancias: {},
    };
  },

  computed: {
    usersResponsaveis() {
      return this.users.filter(element => element.role.type == 'responsible')
    }
  },

  methods: {
    async getInstancias() {
      this.loadingInstancias = true;
      const { data } = await instanceAxios.get("instances");

      this.instancias = data;

      this.loadingInstancias = false;
    },

    editarEvento() {
      try {
        let startDate = this.eventoEditado.date.split("-");
        let endDate = this.eventoEditado.date.split("-");

        let start = new Date(
          parseInt(startDate[0]),
          parseInt(startDate[1]) - 1,
          parseInt(startDate[2]),
          parseInt(this.eventoEditado.horaInicio.split(":")[0]),
          parseInt(this.eventoEditado.horaInicio.split(":")[1])
        );

        let end = new Date(
          parseInt(endDate[0]),
          parseInt(endDate[1]) - 1,
          parseInt(endDate[2]),
          parseInt(this.eventoEditado.horaFim.split(":")[0]),
          parseInt(this.eventoEditado.horaFim.split(":")[1])
        );

        const update = {
          responsaveis: this.eventoEditado.responsaveis,
          solicitantes: this.eventoEditado.solicitantes,
          observacao: this.eventoEditado.observacao,
          descricao: this.eventoEditado.descricao,
          tipoSolicitante: this.eventoEditado.tipoSolicitante,
          tipo: this.eventoEditado.tipo,
          status: this.eventoEditado.status,
          date: this.eventoEditado.date,
          start: start.getTime(),
          end: end.getTime(),
        };

        window.db
          .collection("eventos")
          .doc(this.eventoEditado.doc)
          .update(update)
          .then((res) => {
            console.log(res);
            ToastService("Evento atualizado.", "success");
          });
      } catch (error) {
        ToastService("Erro", "error");
      }
    },
    editEventModal() {
      this.eventoEditado = this.selectedEvent;
      this.dialogEdit = true;
    },
    salvarEvento() {
      if (!this.evento.date) {
        ToastService(
          "DATA não preenchida. Verifique o preenchimento dos dados. ",
          "error"
        );
        return;
      }

      if (this.evento.recorrencia != "nenhum" && !this.evento.dataLimite) {
        ToastService(
          "DATA não preenchida. Verifique o preenchimento dos dados. ",
          "error"
        );
        return;
      }

      console.log(this.evento.dataLimite);

      let startDate = this.evento.date.split("-");
      let endDate = this.evento.date.split("-");

      let start = new Date(
        parseInt(startDate[0]),
        parseInt(startDate[1]) - 1,
        parseInt(startDate[2]),
        parseInt(this.evento.horaInicio.split(":")[0]),
        parseInt(this.evento.horaInicio.split(":")[1])
      );
      let end = new Date(
        parseInt(endDate[0]),
        parseInt(endDate[1]) - 1,
        parseInt(endDate[2]),
        parseInt(this.evento.horaFim.split(":")[0]),
        parseInt(this.evento.horaFim.split(":")[1])
      );

      if (this.evento.recorrencia == "nenhum") {
        this.evento = {
          ...this.evento,
          processoDoc: this.processoDoc,
          start: start.getTime(),
          end: end.getTime(),
          created_at: Date.now(),
          typeEvento: this.typeEvento || "compromisso",
        };

        try {
          window.db
            .collection("eventos")
            .add(this.evento)
            .then((res) => {
              console.log(res);
            });
          ToastService("Evento criado.", "success");
        } catch (error) {
          console.log(error);
        }
      }

      let qtd = this.calcDays(this.evento.date, this.evento.dataLimite);

      if (this.evento.recorrencia == "diario") {
        for (let index = 0; index <= qtd; index++) {
          let startSeguinte = new Date(start.getTime());
          let endSeguinte = new Date(end.getTime());

          startSeguinte.setDate(startSeguinte.getDate() + index);
          endSeguinte.setDate(endSeguinte.getDate() + index);

          var d = new Date(startSeguinte),
            month = "" + (d.getMonth() + 1),
            day = "" + d.getDate(),
            year = d.getFullYear();

          if (month.length < 2) month = "0" + month;
          if (day.length < 2) day = "0" + day;

          this.evento.date = [year, month, day].join("-");

          this.evento = {
            ...this.evento,
            processoDoc: this.processoDoc,
            start: startSeguinte.getTime(),
            end: endSeguinte.getTime(),
            created_at: Date.now(),
          };

          try {
            window.db
              .collection("eventos")
              .add(this.evento)
              .then((res) => {
                console.log(res);
              });
            ToastService("Evento criado.", "success");
          } catch (error) {
            console.log(error);
          }
        }
      } else if (this.evento.recorrencia == "semanal") {
        console.log(qtd);
        qtd = qtd / 7;
        console.log(qtd);
        qtd = parseInt(qtd);
        console.log(qtd);
        if (qtd >= 1) {
          for (let index = 0; index <= qtd; index++) {
            let startSeguinte = new Date(start.getTime());
            let endSeguinte = new Date(end.getTime());
            startSeguinte.setDate(startSeguinte.getDate() + index * 7);
            endSeguinte.setDate(endSeguinte.getDate() + index * 7);

            (d = new Date(startSeguinte)),
              (month = "" + (d.getMonth() + 1)),
              (day = "" + d.getDate()),
              (year = d.getFullYear());

            if (month.length < 2) month = "0" + month;
            if (day.length < 2) day = "0" + day;

            this.evento.date = [year, month, day].join("-");

            this.evento = {
              ...this.evento,
              processoDoc: this.processoDoc,
              start: startSeguinte.getTime(),
              end: endSeguinte.getTime(),
              created_at: Date.now(),
            };

            try {
              window.db
                .collection("eventos")
                .add(this.evento)
                .then((res) => {
                  console.log(res);
                });

              ToastService("Evento criado.", "success");
            } catch (error) {
              console.log(error);
            }
          }
        }
      }

      if (this.evento.recorrencia == "mensal") {
        console.log(qtd);
        qtd = qtd / 30;
        console.log(qtd);
        qtd = parseInt(qtd);
        console.log(qtd);
        if (qtd >= 1) {
          for (let index = 0; index <= qtd; index++) {
            let startSeguinte = new Date(start.getTime());
            let endSeguinte = new Date(end.getTime());
            startSeguinte.setDate(startSeguinte.getDate() + index * 30);
            endSeguinte.setDate(endSeguinte.getDate() + index * 30);
            (d = new Date(startSeguinte)),
              (month = "" + (d.getMonth() + 1)),
              (day = "" + d.getDate()),
              (year = d.getFullYear());

            if (month.length < 2) month = "0" + month;
            if (day.length < 2) day = "0" + day;

            this.evento.date = [year, month, day].join("-");

            this.evento = {
              ...this.evento,
              processoDoc: this.processoDoc,
              start: startSeguinte.getTime(),
              end: endSeguinte.getTime(),
              created_at: Date.now(),
            };

            try {
              window.db
                .collection("eventos")
                .add(this.evento)
                .then((res) => {
                  console.log(res);
                });

              ToastService("Evento criado.", "success");
            } catch (error) {
              console.log(error);
            }
          }
        }
      }
    },
    excluirEvento() {
      window.db
        .collection("eventos")
        .doc(this.selectedEvent.doc)
        .delete()
        .then(() => {
          ToastService("Compromisso cancelado com sucesso", "success");
        })
        .catch((error) => {
          console.log(error);
          ToastService("Não foi possivel cancelar este compromisso", "error");
        });
      this.dialogTrash = false;
    },
    calcDays(start, end) {
      start = start + " 00:00";
      end = end + " 00:00";

      const DAY = 1000 * 60 * 60 * 24;

      start = new Date(start);
      end = new Date(end);

      let difference = Math.abs(start.getTime() - end.getTime());

      return Math.round(difference / DAY);
    },
    async showUsers() {
      try {
        this.users = await this.UserService.index("users");
      } catch (exception) {
        console.log(exception);
      }
    },

    async showClientes() {
      try {
        this.clientes = await this.UserService.index("customers");
      } catch (exception) {
        console.log(exception);
      }
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        requestAnimationFrame(() =>
          requestAnimationFrame(() => (this.selectedOpen = true))
        );
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
    convertDate(created_at) {
      var date = new Date(created_at);

      return date.toLocaleString("pt-BR", {
        hour: "numeric", // numeric, 2-digit
        minute: "numeric", // numeric, 2-digit
        day: "2-digit", // numeric, 2-digit
        year: "2-digit", // numeric, 2-digit
        month: "2-digit", // numeric, 2-digit, long, short, narrow
      });
    },
    convertSemHorario(created_at) {
      var date = new Date(created_at);

      return date.toLocaleString("pt-BR", {
        day: "2-digit", // numeric, 2-digit
        year: "2-digit", // numeric, 2-digit
        month: "2-digit", // numeric, 2-digit, long, short, narrow
      });
    },
    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },
    getDate(payload) {
      this.evento.date = payload.date;
    },
    getDataLimite(payload) {
      this.evento.dataLimite = payload.date;
    },
    getTimeInicio(payload) {
      this.evento.horaInicio = payload.time;
    },
    getTimeFim(payload) {
      this.evento.horaFim = payload.time;
    },
    getDateEdit(payload) {
      this.eventoEditado.date = payload.date;
    },
    getTimeInicioEdit(payload) {
      this.eventoEditado.horaInicio = payload.time;
    },
    getTimeFimEdit(payload) {
      this.eventoEditado.horaFim = payload.time;
    },
    getEvents({ start, end }) {
      this.loadTipos();

      const min = new Date(`${start.date}T00:00:00`);
      const max = new Date(`${end.date}T23:59:59`);
      var self = this;
      window.db
        .collection("eventos")
        .where("start", ">=", min.getTime())
        .where("start", "<=", max.getTime())
        .onSnapshot((query) => {
          let eventos = [];

          query.forEach((obj) => {
            const data = obj.data();
            console.log(data);
            console.log(self.tipos);

            eventos.push({
              doc: obj.id,
              name: data.tipo,
              start: new Date(data.start),
              end: new Date(data.end),
              color: self.tipos.find((element) => element.tipo == data.tipo)[
                "color"
              ],
              timed: true,
              details: data.descricao,
              ...data,
            });
          });

          this.events = eventos;
        });

      //const days = (max.getTime() - min.getTime()) / 86400000;
      //const eventCount = this.rnd(days, days + 20);

      /**  for (let i = 0; i < eventCount; i++) {
        const allDay = this.rnd(0, 3) === 0;
        const firstTimestamp = this.rnd(min.getTime(), max.getTime());
        const first = new Date(firstTimestamp - (firstTimestamp % 900000));
        const secondTimestamp = this.rnd(2, allDay ? 288 : 8) * 900000;
        const second = new Date(first.getTime() + secondTimestamp);

        events.push({
          name: this.names[this.rnd(0, this.names.length - 1)],
          start: first,
          end: second,
          color: this.colors[this.rnd(0, this.colors.length - 1)],
          timed: !allDay,
        });
      }*/

      //this.events = this.eventos;
    },
    getEventColor(event) {
      return event.color;
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
    salvarTipo() {
      try {
        let self = this;
        window.db
          .collection("tipos")
          .add({
            tipo: this.novoTipo,
            color: "#" + ((Math.random() * 0xffffff) << 0).toString(16),
          })
          .then((res) => {
            console.log(res);
            self.dialogTipo = false;
            self.novoTipo = "";
          });
      } catch (error) {
        console.log(error);
      }
    },
    async loadTipos() {
      window.db.collection("tipos").onSnapshot((query) => {
        let tipos = [];
        query.forEach((obj) => {
          tipos.push({ doc: obj.id, ...obj.data() });
        });

        this.tipos = tipos;
      });
    },

    getNameCliente(id) {
      const cliente = this.clientes.find((element) => element.id == id);
      if (cliente) {
        return cliente.nome_responsavel;
      }
    },
    getNameUsuario(id) {
      const solicitante = this.users.find((element) => element.id == id);
      if (solicitante) {
        return solicitante.name;
      }
    },
  },
};
</script>

<style scoped>
.height-size {
}
</style>
