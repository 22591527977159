<template>
  <v-container>
    <h2>Processo</h2>
    <div class="text-center">
      <v-progress-circular
        :size="100"
        color="primary"
        indeterminate
        v-show="Object.keys(processo).length === 0"
      ></v-progress-circular>
    </div>
    <br /><br />
    <v-card v-if="Object.keys(processo).length !== 0">
      <v-container>
        <v-row>
          <v-chip
            @click="editar = !editar"
            color="blue"
            v-text="'Editar'"
            class="ma-2"
            label
            small
          >
          </v-chip>

          <edit
            :dialog="editar"
            @closeModal="editar = false"
            :processId="processo.id"
          ></edit>
        </v-row>
        <v-row class="text-center">
          <v-col cols="2" class="title" style="border: 1px solid white">
            Titulo
          </v-col>
          <v-col cols="10" style="border: 1px solid #eceeef">
            {{ processo.title || "Sem titulo" }}
          </v-col>
        </v-row>
        <v-row class="text-center">
          <v-col cols="2" class="title" style="border: 1px solid white">
            Tipo de ação
          </v-col>
          <v-col cols="10" style="border: 1px solid #eceeef">
            {{ processo.action.name || "Sem tipo de ação" }}
          </v-col>
        </v-row>
        <v-row class="text-center">
          <v-col cols="2" class="title" style="border: 1px solid white">
            Material
          </v-col>
          <v-col cols="10" style="border: 1px solid #eceeef">
            {{ processo.material.name || "Sem material" }}
          </v-col>
        </v-row>
        <v-row class="text-center">
          <v-col cols="2" class="title" style="border: 1px solid white">
            Status
          </v-col>
          <v-col cols="10" style="border: 1px solid #eceeef">
            {{ processo.status || "Sem status" }}
          </v-col>
        </v-row>
        <v-row class="text-center">
          <v-col cols="2" class="title" style="border: 1px solid white">
            Ritos
          </v-col>
          <v-col cols="10" style="border: 1px solid #eceeef">
            {{ processo.rite.name || "Sem rito" }}
          </v-col>
        </v-row>
        <v-row class="text-center">
          <v-col cols="2" class="title" style="border: 1px solid white">
            Eletronico
          </v-col>
          <v-col cols="10" style="border: 1px solid #eceeef">
            <template v-if="processo.electronic">
              {{ processo.electronic ? "Sim" : "Não" }}
            </template>
          </v-col>
        </v-row>
        <v-row class="text-center">
          <v-col cols="2" class="title" style="border: 1px solid white">
            Estrategico
          </v-col>
          <v-col cols="10" style="border: 1px solid #eceeef">
            <template v-if="processo.strategic">
              {{ processo.strategic ? "Sim" : "Não" }}
            </template>
          </v-col>
        </v-row>
        <v-row class="text-center">
          <v-col cols="2" class="title" style="border: 1px solid white">
            Distribuição
          </v-col>
          <v-col cols="10" style="border: 1px solid #eceeef">
            {{ processo.distribution | formatDate }}
          </v-col>
        </v-row>
        <v-row class="text-center">
          <v-col cols="2" class="title" style="border: 1px solid white">
            Citação
          </v-col>
          <v-col cols="10" style="border: 1px solid #eceeef">
            {{ processo.citation | formatDate }}
          </v-col>
        </v-row>
        <v-row class="text-center">
          <v-col cols="2" class="title" style="border: 1px solid white">
            Liquidação
          </v-col>
          <v-col cols="10" style="border: 1px solid #eceeef">
            {{ processo.liquidation | formatDate }}
          </v-col>
        </v-row>
        <v-row class="text-center">
          <v-col cols="2" class="title" style="border: 1px solid white">
            Causa
          </v-col>
          <v-col cols="10" style="border: 1px solid #eceeef">
            {{ processo.cause }}
          </v-col>
        </v-row>
        <v-row class="text-center">
          <v-col cols="2" class="title" style="border: 1px solid white">
            Contigencia
          </v-col>
          <v-col cols="10" style="border: 1px solid #eceeef">
            {{ processo.contingency }}
          </v-col>
        </v-row>
        <v-row class="text-center">
          <v-col cols="2" class="title" style="border: 1px solid white">
            Probabilidade
          </v-col>
          <v-col cols="10" style="border: 1px solid #eceeef">
            {{ processo.probability }}
          </v-col>
        </v-row>
        <v-row class="text-center">
          <v-col cols="2" class="title" style="border: 1px solid white">
            Fase
          </v-col>
          <v-col cols="10" style="border: 1px solid #eceeef">
            {{ processo.phase.name }}
          </v-col>
        </v-row>
        <v-row class="text-center">
          <v-col cols="2" class="title" style="border: 1px solid white">
            Fase
          </v-col>
          <v-col cols="10" style="border: 1px solid #eceeef">
            {{ processo.date_phase | formatDate }}
          </v-col>
        </v-row>
        <v-row class="text-center">
          <v-col cols="2" class="title" style="border: 1px solid white">
            Descrição
          </v-col>
          <v-col cols="10" style="border: 1px solid #eceeef">
            {{ processo.description || "Sem descrição" }}
          </v-col>
        </v-row>
      </v-container>
    </v-card>

    <!-- EVENTOS A FAZER -->
    <tabela-eventos :processoDoc="processoDoc"></tabela-eventos>
    <!-- FIM EVENTOS A FAZER -->

    <!-- ANDAMENTOS -->
    <andamentos :processoDoc="processoDoc"></andamentos>
    <!-- FIM ANDAMENTO -->
  </v-container>
</template>

<script>
import Edit from "./Edit.vue";
import Andamentos from "./Tabelas/Andamentos.vue";
import TabelaEventos from "./Tabelas/TabelaEventos.vue";
import instanceAxios from "../../plugins/axios";
import moment from "moment";
export default {
  components: {
    TabelaEventos,
    Edit,
    Andamentos,
  },
  filters: {
    formatDate: function (value) {
      if (!value) return "";
      return moment(value).format("DD/MM/YYYY");
    },
  },
  data() {
    return {
      editar: false,

      objetoModal: false,
      partesModal: false,
      advogadosModal: false,

      processo: {},
      processoDoc: undefined,
      acoes: [],
      materias: [],
      ritos: [],
      fases: [],
      tiposPartes: [],
      tiposObjetos: [],

      headers: [
        { text: "Titulo", value: "titulo", mutation: false },
        {
          text: "Tipo de ação",
          value: "acoes",
          mutation: true,
          table: "acoes",
        },
        {
          text: "Materia",
          value: "materia",
          mutation: true,
          table: "materias",
        },
        { text: "Pasta", value: "pasta", mutation: false },
        { text: "Data Status", value: "dataStatus", mutation: false },
        { text: "Status", value: "status", mutation: false },
        { text: "Rito", value: "ritos", mutation: true, table: "ritos" },
        { text: "Eletronico", value: "eletronico", mutation: false },
        { text: "Estrategico", value: "estrategico", mutation: false },
        {
          text: "Data Distribuição",
          value: "dataDistribuicao",
          mutation: false,
        },
        { text: "Data Citação", value: "dataCitacao", mutation: false },
        {
          text: "Data de Referência da Liquidação",
          value: "dataLiquidacao",
          mutation: false,
        },
        { text: "Valor da Causa", value: "valorCausa", mutation: false },
        {
          text: "Valor Provisionado",
          value: "valorProvisionado",
          mutation: false,
        },
        {
          text: "Valor contigencia",
          value: "valorContigencia",
          mutation: false,
        },
        { text: "Risco (%)", value: "riscoPorcentagem", mutation: false },
        {
          text: "Probabilidade de Exito",
          value: "probabilidade",
          mutation: false,
        },
        { text: "Fase", value: "fase", mutation: true, table: "fases" },
        { text: "Data fase", value: "dataFase", mutation: false },
        {
          text: "Descrição do Objeto",
          value: "descricaoObjeto",
          mutation: false,
        },
      ],

      loadingProcesso: true,

      objetosHeader: [
        {
          text: "Tipo do Objeto",
          value: "tipo",
          class: "header",
        },
        {
          text: "Descrição",
          value: "descricao",
          class: "header",
        },
        {
          text: "Principal",
          value: "principal",
          class: "header",
        },
      ],
      objetos: [],

      partesHeader: [
        {
          text: "Nome",
          value: "nome",
          class: "header",
        },
        {
          text: "Tipo de Pessoa",
          value: "tipo",
          class: "header",
        },
        {
          text: "Tipo Parte",
          value: "tipoParte",
          class: "header",
        },
      ],
      partes: [],

      advogadosHeader: [
        { text: "Nome", value: "nome", class: "header" },
        { text: "Parte", value: "tipoParte", class: "header" },
      ],
      advogados: [],
      selectedItemsAdvogados: [],
      selectedItemsObjetos: [],
      selectedItemsPartes: [],
    };
  },
  methods: {
    async getProcesso() {
      this.loadingProcesso = true;

      const { data } = await instanceAxios.get(
        `lawsuits/${this.$route.params.id}`
      );

      this.processo = data;

      this.loadingProcesso = false;
    },
    get(array, acaoId) {
      let value = this[array].find((element) => element.doc == acaoId);

      return value.titulo;
    },

    getValueIndex(index) {
      return this.processo[index];
    },
  },
  mounted() {
    this.getProcesso();
    this.processoDoc = this.$route.params.id;
  },
};
</script>

<style>
.title {
  background-color: #053253 !important;
  color: White !important;
  font-size: 10pt !important;
}

.col {
  padding-top: 6px;
  padding-bottom: 6px;
}

.header {
  background-color: #053253 !important;
  color: white !important;
}
</style>
