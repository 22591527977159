<template>
  <div>
    <v-card>
      <v-card-title> Eventos a Fazer Não Realizados </v-card-title>
      <v-card-text>
        <v-container>
          <v-data-table
            :no-data-text="'Não existem cadastros para este processo.'"
            :headers="headers"
            show-select
            :single-select="false"
            v-model="selectedItems"
            item-key="doc"
            :items="items"
            :items-per-page="5"
            class="elevation-1"
            :footer-props="{
              itemsPerPageAllText: 'Todos',
            }"
          >
            <template v-slot:top>
              <div class="d-flex flex-row-reverse pb-2">
                <v-btn
                  color="error"
                  @click="removerSelecionados()"
                  x-small
                  class="pr-1"
                >
                  Excluir selecionados
                </v-btn>
                <novo-compromisso
                  small
                  :processoDoc="processoDoc"
                  :typeEvento="'evento'"
                  text="Incluir"
                >
                </novo-compromisso>
                <v-btn x-small color="success" @click="realizaSelecionados()">
                  Realizar Selecionados
                </v-btn>
              </div>
            </template>
            <template v-slot:item.start="{ item }">
              <span style="color:red"> {{ item.start | formatDate }} </span>
            </template>
            <template v-slot:item.end="{ item }">
              {{ item.start | formatHour }}
            </template>
            <template v-slot:item.instancia="{ item }">
              <span style="color:red">
                {{ getInstance(item.instance_id) }}
              </span>
            </template>
            <template v-slot:item.tipo="{ item }">
              <span style="color:red">
                {{ item.schedule_type.name }}
              </span>
            </template>
            <template v-slot:item.responsibles="{ item }">
              {{ item.responsible.name }}
            </template>
          </v-data-table>
        </v-container>
      </v-card-text>
    </v-card>
    <agenda></agenda>
    <agenda :processoDoc="processoDoc" :typeEvento="'afazer'"></agenda>
  </div>
</template>

<script>
import ToastService from "@/services/ToastService";
import Agenda from "@/components/Compromisso/Form";
import instanceAxios from "../../../plugins/axios";
import moment from "moment";
import NovoCompromisso from "../../../components/Agenda/NovoCompromisso.vue";
export default {
  props: {
    processoDoc: String,
  },
  components: {
    Agenda,
    NovoCompromisso,
  },

  filters: {
    formatDate: function(el) {
      return moment(el).format("DD/MM/YYYY");
    },

    formatHour: function(el) {
      return moment(el).format("HH:mm");
    },
  },

  watch: {
    modal: function() {
      console.log("oi");
    },
    processoDoc: function(doc) {
      if (doc) this.getItemsById(doc);
    },
  },
  data() {
    return {
      headers: [
        {
          text: "Data",
          value: "start",
          class: "header",
        },
        {
          text: "Hora",
          value: "end",
          class: "header",
        },
        {
          text: "Tipo",
          value: "tipo",
          class: "header",
          cellClass: "header-red",
        },
        {
          text: "Instancia",
          value: "instancia",
          class: "header",
          cellClass: "header-red",
        },
        {
          text: "Responsavel",
          value: "responsibles",
          class: "header",
          cellClass: "header-red",
        },
        { text: "Descrição", value: "description", class: "header" },
        { text: "Complemento", value: "complement", class: "header" },
        { text: "Observação", value: "observation", class: "header" },
      ],
      selectedItems: [],
      items: [],
      orgaos: [],
      instancias: [],
      modal: false,

      users: [],
      loadingUsers: false,

      instances: [],
      loadingInstancias: false,
    };
  },

  methods: {
    realizaSelecionados() {
      try {
        this.selectedItems.forEach((element) => {
          instanceAxios.put(`schedules/types/${element.id}`);
        });

        this.getItemsById(this.processoDoc);
      } catch (error) {
        console.log(error);
        ToastService("Erro ao tentar excluir", "error");
      }
    },

    async getInstancias() {
      this.loadingInstancias = true;
      const { data } = await instanceAxios.get("instances");

      this.instancias = data;

      this.loadingInstancias = false;
    },

    async getUsers() {
      this.loadingUsers = true;
      const { data } = await instanceAxios.get("users");

      this.users = data;

      this.loadingUsers = false;
    },

    getInstance(val) {
      if (val && this.instancias.length > 0)
        return this.instancias.find((element) => element.id == val).name;
    },

    getUser(val) {
      if (val && this.users.length > 0)
        return this.users.find((element) => element.id == val).name;
    },
    convertDate(created_at) {
      var date = new Date(created_at);

      return date.toLocaleString("pt-BR", {
        hour: "numeric", // numeric, 2-digit
        minute: "numeric", // numeric, 2-digit
        day: "2-digit", // numeric, 2-digit
        year: "2-digit", // numeric, 2-digit
        month: "2-digit", // numeric, 2-digit, long, short, narrow
      });
    },
    async getItemsById(id) {
      const { data } = await instanceAxios.get(`schedules/lawsuit/${id}?tipo=andamento`);

      console.log(data);

      console.log("data ", data);
      this.items = data.filter((item) => {
        return !item.conditions.find((el) => el.name == "Realizado");
      });
    },
    getById(tabela) {
      window.db.collection(tabela).onSnapshot((query) => {
        let values = [];

        query.forEach((element) => {
          values.push({
            doc: element.id,
            ...element.data(),
          });
        });

        this[tabela] = values;
      });
    },
    get(array, acaoId) {
      let value = this[array].find((element) => element.doc == acaoId);

      if (value) {
        return value.titulo;
      }
    },
    async removerSelecionados() {
      try {
        this.selectedItems.forEach(async (element) => {
          await instanceAxios
            .delete(`schedules/${element.id}`)
            .then(() => {
              ToastService("Compromisso cancelado com sucesso", "success");
              this.getItemsById(this.processoDoc);
            })
            .catch((error) => {
              console.log('erro ', error);
              ToastService(
                "Não foi possivel cancelar este compromisso",
                "error"
              );
            });
        });
      } catch (error) {
        console.log(error);
        ToastService("Erro ao tentar excluir", "error");
      }
    },
    getTabelas(tabela, array = null) {
      if (!array) {
        array = tabela;
      }

      window.db.collection(tabela).onSnapshot((query) => {
        let values = [];

        query.forEach((obj) => {
          values.push({ doc: obj.id, ...obj.data() });
        });

        this[array] = values;
      });
    },
  },

  mounted() {
    this.getInstancias();
    this.getUsers();

    if (this.processoDoc) {
      this.getItemsById(this.processoDoc);
    }
  },
};
</script>

<style>
.header-red {
  color: red !important;
}
</style>
