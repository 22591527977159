<template>
  <div>
    <v-dialog width="80%" v-model="dialogData">
      <v-card>
        <v-card-title>
          Editar Processo
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form v-if="processo">
              <v-row>
                <v-col cols="6">
                  <v-select
                    :rules="requiredRule"
                    :items="users"
                    item-text="name"
                    item-value="id"
                    v-model="processo.user_active"
                    label="Polo Ativo"
                    :loading="loadingUsers"
                  ></v-select>
                </v-col>
                <v-col cols="6">
                  <v-select
                    :rules="requiredRule"
                    :items="users"
                    item-text="name"
                    item-value="id"
                    v-model="processo.user_passive"
                    label="Polo Passivo"
                    :loading="loadingUsers"
                  ></v-select>
                </v-col>
                <v-col cols="8">
                  <v-text-field
                    label="Titulo do processo"
                    v-model="processo.title"
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    label="Numero do processo"
                    v-model="processo.number"
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <div class="d-flex flex-row align-center">
                    <v-select
                      label="Materia"
                      :items="materias"
                      item-text="name"
                      item-value="id"
                      v-model="processo.material_id"
                      :loading="loadingMaterias"
                    ></v-select>
                    <v-btn icon @click="materiasModalShow = true">
                      <v-icon small color="success"> fas fa-plus </v-icon>
                    </v-btn>
                  </div>
                </v-col>
                <v-col cols="6">
                  <div class="d-flex flex-row align-center">
                    <v-select
                      label="Tipo de ações"
                      :items="acoes"
                      item-text="name"
                      item-value="id"
                      v-model="processo.action_id"
                      :loading="loadingAcoes"
                    ></v-select>
                    <v-btn icon @click="acoesModalShow = true">
                      <v-icon small color="success"> fas fa-plus </v-icon>
                    </v-btn>
                  </div>
                </v-col>
                <v-col cols="6">
                  <div class="d-flex flex-row align-center">
                    <v-select
                      label="Tipo de Objeto"
                      :items="objetos"
                      item-text="name"
                      item-value="id"
                      v-model="processo.object_id"
                      :loading="loadingObjetos"
                    ></v-select>
                    <v-btn icon @click="objetosModalShow = true">
                      <v-icon small color="success"> fas fa-plus </v-icon>
                    </v-btn>
                  </div>
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    label="Descrição do Objeto"
                    v-model="processo.description"
                  ></v-textarea>
                </v-col>
                <v-col cols="6">
                  <div class="d-flex flex-row align-center">
                    <v-select
                      label="Tipo de Rito"
                      :items="ritos"
                      item-text="name"
                      item-value="id"
                      v-model="processo.rite_id"
                      :loading="loadingRitos"
                    ></v-select>
                    <v-btn icon @click="ritosModalShow = true">
                      <v-icon small color="success"> fas fa-plus </v-icon>
                    </v-btn>
                  </div>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    label="Pasta"
                    v-model="processo.folder"
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <DatePick
                    required
                    @dateSelected="getDataDistribuicao"
                    label="Data da Distribuição"
                    :changeData="processo.distribution"
                  ></DatePick>
                </v-col>
                <v-col cols="3">
                  <DatePick
                    required
                    @dateSelected="getDataCitacao"
                    label="Data da Citação"
                    :changeData="processo.citation"
                  ></DatePick>
                </v-col>
                <v-col cols="3">
                  <div class="d-flex flex-row align-center">
                    <v-select
                      label="Processo Eletrônico"
                      :items="tipoBoleanos"
                      :item-text="'text'"
                      :item-value="'value'"
                      v-model="processo.electronic"
                    ></v-select>
                  </div>
                </v-col>
                <v-col cols="3">
                  <div class="d-flex flex-row align-center">
                    <v-select
                      label="Processo Estratégico"
                      :items="tipoBoleanos"
                      :item-text="'text'"
                      :item-value="'value'"
                      v-model="processo.strategic"
                    ></v-select>
                  </div>
                </v-col>
                <v-col cols="12">
                  <div class="d-flex flex-row align-center">
                    <v-select
                      label="Probabilidade de Exito"
                      :items="probalidades"
                      v-model="processo.probability"
                    ></v-select>
                  </div>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    label="Valor da Causa"
                    v-model="processo.cause"
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <DatePick
                    required
                    @dateSelected="getDataLiquidation"
                    label="Data de Referência da Liquidação"
                    :changeData="processo.liquidation"
                  ></DatePick>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    label="Valor Provisionado"
                    v-model="processo.provisioned_value"
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <DatePick
                    required
                    @dateSelected="getDataValorProvisionado"
                    label="Data do Valor Provisionado"
                    :changeData="processo.provisioned"
                  ></DatePick>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    label="Valor da contigencia"
                    v-model="processo.contingency"
                  ></v-text-field>
                </v-col>
                <v-col cols="2">
                  <v-text-field
                    label="Risco (%)"
                    v-model="processo.risk"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="4">
                  <DatePick
                    required
                    @dateSelected="getDataStatus"
                    label="Data do Status"
                    :changeData="processo.status_date"
                  ></DatePick>
                </v-col>
                <v-col cols="4">
                  <v-select
                    label="Status"
                    :items="status"
                    v-model="processo.status"
                  ></v-select>
                </v-col>
                <!--  <v-col cols="4">
              <v-select
                label="Prioridade de"
                :items="users"
                item-value="id"
                item-text="name"
                v-model="processo.prioridade"
                :loading="loadingUsers"
              ></v-select>
            </v-col> -->
              </v-row>
              <v-row>
                <v-col cols="4">
                  <DatePick
                    required
                    @dateSelected="getDataFase"
                    label="Data da fase"
                    :changeData="processo.date_phase"
                  ></DatePick>
                </v-col>
                <v-col cols="4">
                  <div class="d-flex flex-row align-center">
                    <v-select
                      label="Fase"
                      :items="fases"
                      item-text="name"
                      item-value="id"
                      v-model="processo.phase_id"
                    ></v-select>
                    <v-btn icon @click="fasesModalShow = true">
                      <v-icon small color="success"> fas fa-plus </v-icon>
                    </v-btn>
                  </div>
                </v-col>
                <v-col cols="4">
                  <div class="d-flex flex-row align-center">
                    <v-select
                      label="Tipo de Instancia"
                      :items="instancias"
                      item-value="id"
                      item-text="name"
                      v-model="processo.instance_id"
                      :loading="loadingInstancias"
                    ></v-select>
                    <v-btn icon @click="instanciasModalShow = true">
                      <v-icon small color="success"> fas fa-plus </v-icon>
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="4">
                  <v-text-field
                    label="Numero"
                    v-model="processo.number"
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <div class="d-flex flex-row align-center">
                    <v-select
                      label="Unidade"
                      :items="unidades"
                      item-value="id"
                      item-text="name"
                      v-model="processo.unit_id"
                      :loading="loadingUnidades"
                    ></v-select>
                    <v-btn icon @click="unidadesModalShow = true">
                      <v-icon small color="success"> fas fa-plus </v-icon>
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <div class="d-flex flex-row align-center">
                    <v-select
                      label="Comarca"
                      :items="comarcas"
                      item-value="id"
                      item-text="name"
                      v-model="processo.district_id"
                      :loading="loadingComarcas"
                    ></v-select>
                    <v-btn icon @click="comarcasModalShow = true">
                      <v-icon small color="success"> fas fa-plus </v-icon>
                    </v-btn>
                  </div>
                </v-col>
                <v-col cols="6">
                  <div class="d-flex flex-row align-center">
                    <v-select
                      label="Órgão"
                      :items="orgaos"
                      item-value="id"
                      item-text="name"
                      v-model="processo.agency_id"
                      :loading="loadingOrgaos"
                    ></v-select>
                    <v-btn icon @click="orgaosModalShow = true">
                      <v-icon small color="success"> fas fa-plus </v-icon>
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
              <v-row class="text-center">
                <v-spacer></v-spacer>
                <v-col cols="12">
                  <v-btn color="primary" @click="salvarProcesso"> Salvar</v-btn>
                </v-col>
                <v-spacer></v-spacer>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import UserService from "@/services/User/UserService";
import ToastService from "@/services/ToastService";
import instanceAxios from "../../plugins/axios";
import DatePick from "../../components/Date/Index.vue";
export default {
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    processId: {
      type: Number,
      default: null,
    },
  },

  components: {
    DatePick,
  },

  watch: {
    dialog: function(dialog) {
      this.dialogData = dialog;
    },

    processoId: function(id) {
      if (id) {
        this.getProcesso();
      }
    },

    modalData: function(modal) {
      if (!modal) {
        this.$emit("closeModal");
      }
    },
  },

  computed: {
    requiredRule: function() {
      return [(v) => !!v || "Campo é obrigatorio"];
    },
  },

  data() {
    return {
      loadingUsers: true,
      dialogData: this.dialog,
      UserService: new UserService(""),
      users: [],

      tipoBoleanos: [
        { value: 1, text: "Sim" },
        { value: 0, text: "Não" },
      ],
      probalidades: ["alta", "media", "baixa"],
      status: ["ativo", "arquivado", "inativo", "suspenso"],
      loading: false,
      id: undefined,
      processo: {},

      materias: [],
      materiasModalShow: false,
      materiasModal: {},
      loadingMaterias: true,

      acoes: [],
      acoesModalShow: false,
      acoesModal: {},
      loadingAcoes: true,

      objetos: [],
      objetosModalShow: false,
      objetosModal: {},
      loadingObjetos: true,

      ritos: [],
      ritosModalShow: false,
      ritosModal: {},

      fases: [],
      fasesModalShow: false,
      fasesModal: {},
      loadingRitos: true,

      instancias: [],
      instanciasModalShow: false,
      instanciasModal: {},
      loadingInstancias: true,

      unidades: [],
      unidadesModalShow: false,
      unidadesModal: {},
      loadingUnidades: true,

      especialidades: [],
      especialidadesModalShow: false,
      especialidadesModal: {},

      comarcas: [],
      comarcasModalShow: false,
      comarcasModal: {},
      loadingComarcas: true,

      orgaos: [],
      orgaosModalShow: false,
      orgaosModal: {},
      loadingOrgaos: true,
    };
  },

  mounted() {
    if (this.processId) {
      this.getProcesso();
    }

    this.getMaterias();
    this.getAcoes();
    this.getObjetos();
    this.getRitos();
    this.showUsers();
    this.getFases();
    this.getInstancias();
    this.getUnidades();
    this.getComarcas();
    this.getOrgaos();
  },

  methods: {
    async getProcesso() {
      this.loadingProcesso = true;

      const { data } = await instanceAxios.get(`lawsuits/${this.processId}`);

      this.processo = data;

      this.loadingProcesso = false;
    },

    async salvarProcesso() {
      try {
        await instanceAxios.put(`lawsuits/${this.processId}`, this.processo);

        ToastService("Processo atualizado.", "success");
      } catch (error) {
        console.log(error);
      }
    },
    async getMaterias() {
      this.loadingMaterias = true;

      const { data } = await instanceAxios.get("materials");

      this.materias = data;

      this.loadingMaterias = false;
    },

    async salvarMateria() {
      await instanceAxios.post("materials", this.materiasModal);

      this.materiasModalShow = false;
      this.materiasModal = {};

      ToastService("Materia criada.", "success");
    },

    async getAcoes() {
      this.loadingAcoes = true;

      const { data } = await instanceAxios.get("actions");

      this.acoes = data;

      this.loadingAcoes = false;
    },

    async salvarAcoes() {
      await instanceAxios.post("actions", this.acoesModal);

      this.acoesModalShow = false;
      this.acoesModal = {};

      ToastService("Ação criada.", "success");
    },

    async getObjetos() {
      this.loadingObjetos = true;
      const { data } = await instanceAxios.get("objects");

      this.objetos = data;
      this.loadingObjetos = false;
    },

    async salvarObjetos() {
      await instanceAxios.post("objects", this.objetosModal);

      this.objetosModalShow = false;
      this.objetosModal = {};

      ToastService("Tipo de objeto criado.", "success");
    },

    async getRitos() {
      this.loadingRitos = true;

      const { data } = await instanceAxios.get("rites");

      this.ritos = data;

      this.loadingRitos = false;
    },

    async salvarRitos() {
      await instanceAxios.post("rites", this.ritosModal);

      this.ritosModalShow = false;
      this.ritosModal = {};

      ToastService("Tipo de rito criado.", "success");
    },

    async getFases() {
      this.loadingFases = true;
      const { data } = await instanceAxios.get("phases");

      this.fases = data;

      this.loadingFases = false;
    },

    async salvarFases() {
      await instanceAxios.post("phases", this.fasesModal);

      this.fasesModalShow = false;
      this.fasesModal = {};

      ToastService("Fase criada.", "success");
    },

    async getInstancias() {
      this.loadingInstancias = true;
      const { data } = await instanceAxios.get("instances");

      this.instancias = data;

      this.loadingInstancias = false;
    },

    async salvarInstancias() {
      await instanceAxios.post("instances", this.instanciasModal);

      this.instanciasModalShow = false;
      this.instanciasModal = {};

      ToastService("Tipo de instancia criada.", "success");
    },

    async getUnidades() {
      this.loadingUnidades = true;
      const { data } = await instanceAxios.get("units");

      this.unidades = data;

      this.loadingUnidades = false;
    },

    async salvarUnidades() {
      await instanceAxios.post("units", this.unidadesModal);

      this.unidadesModalShow = false;
      this.unidadesModal = {};

      ToastService("Unidade criada.", "success");
    },

    async getComarcas() {
      this.loadingComarcas = true;

      const { data } = await instanceAxios.get("districts");
      this.comarcas = data;

      this.loadingComarcas = false;
    },

    async salvarComarcas() {
      await instanceAxios.post("districts", this.comarcasModal);

      this.comarcasModalShow = false;
      this.comarcasModal = {};

      ToastService("Comarca criada.", "success");
    },

    async getOrgaos() {
      this.loadingOrgaos = true;
      const { data } = await instanceAxios.get("agencys");
      this.orgaos = data;

      this.loadingOrgaos = false;
    },

    async salvarOrgaos() {
      await instanceAxios.post("agencys", this.comarcasModal);

      this.orgaosModalShow = false;
      this.orgaosModal = {};

      ToastService("Orgão criado.", "success");
    },

    getDataLiquidation(payload) {
      this.processo.liquidation = payload.date;
    },

    getDataDistribuicao(payload) {
      this.processo.dataDistribuicao = payload.date;
    },

    getDataCitacao(payload) {
      this.processo.dataCitacao = payload.date;
    },

    getDataValorProvisionado(payload) {
      this.processo.dataValorProvisionado = payload.date;
    },
    getDataStatus(payload) {
      this.processo.dataStatus = payload.date;
    },
    getDataFase(payload) {
      this.processo.dataFase = payload.date;
    },

    async showUsers() {
      try {
        this.loadingUsers = true;
        this.users = await this.UserService.index("users");

        this.loadingUsers = false;
      } catch (exception) {
        console.log(exception);
      }
    },
  },
};
</script>

<style></style>
