<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :return-value.sync="date"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="dateFormatted"
        :label="label"
        hint="MM/DD/YYYY"
        persistent-hint
        prepend-icon="fas fa-calendar-day"
        readonly
        v-bind="attrs"
        v-on="on"
        :error-messages="error"
        @blur="date = parseDate(dateFormatted)"
      ></v-text-field>      
    </template>
    <v-date-picker v-model="date" no-title locale="pt-BR" >
      <v-spacer></v-spacer>
      <v-btn text color="primary" @click="menu = false"> Cancelar </v-btn>
      <v-btn text color="primary" @click="setDate"> OK </v-btn>
    </v-date-picker>
  </v-menu>
</template>

<script>
export default {
  props: {
    error: undefined,
    label: undefined,
    changeData: undefined,
  },
  data() {
    return {
      menu: false,
      date: undefined,
      dateFormatted: undefined,
    };
  },

  computed: {
    computedDateFormatted() {
      return this.formatDate(this.date);
    },
  },

  watch: {
    changeData: function(value) {
      this.date = value;
    },
    date() {
      this.dateFormatted = this.formatDate(this.date);
    },
  },

  methods: {
    setDate() {
      this.$refs.menu.save(this.date);
      this.$emit("dateSelected", { date: this.date });
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
  },
};
</script>